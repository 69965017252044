import * as React from 'react';
import type {GatsbyBrowser} from 'gatsby';
import {ErrorBoundary} from './src/components/error-boundary';
import {Helmet} from 'react-helmet';
import {Layout} from './src/components/layout';
import {Provider} from 'react-redux';
import store from './src/state/store';
import {createTheme, ThemeProvider} from '@mui/material';
import {Location, LocationContext} from '@reach/router';
const theme = createTheme({
    palette: {
        primary: {
            main: '#009dc6',
        },
    },
});

const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
    element,
}) => {
    return (
        <ErrorBoundary>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Media Manager Handbuch</title>
            </Helmet>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <Location>
                        {(locationContext: LocationContext) => {
                            return locationContext.location.pathname.includes('/login')
                                ? (element)
                                : (<Layout children={element}></Layout>);
                        }
                        }
                    </Location>
                </Provider>
            </ThemeProvider>
        </ErrorBoundary>
    );
};

export {wrapRootElement};
