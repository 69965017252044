class CoreCookieService {

    constructor(private cookieString: string = document.cookie || '') {}

    /**
     * Returns the value for the given key.
     *
     * @param key that should be used to fetch a value.
     *
     * @returns Raw cookie value. Always a string, if you saved other data types you need to convert them back by yourself.
     */
    public get(key: string): string | null {
        return getCookiesMap(this.cookieString).get(key) || null;
    }

    /**
     * Returns all current stored cookie keys
     */
    public getAllKeys(): Array<string> {
        return Array.from(getCookiesMap(this.cookieString).keys());
    }

    /**
     * Checks whether there is a cookie set for the given key.
     *
     * @param key that should be used to look up a value
     *
     * @returns whether there is a cookie set
     */
    public has(key: string): boolean {
        return getCookiesMap(this.cookieString).has(key);
    }

    /**
     * Sets a cookie value by key to the browser. Both values should be strings.
     * If you want to store objects or other complex types you need to stringify your result
     *
     * @param key of the cookie
     * @param value of the cookie
     * @param expires the time the cookie should expire
     * @param path the path on which the cookie should be set. Only important if you run multiple projects on the same domain
     */
    public set(key: string, value: string, expires?: Date, path: string = '/'): void {
        const cookieValue: string = encodeURIComponent(value);
        let cookieString: string = `${encodeURIComponent(key)}=${cookieValue}`;
        cookieString += expires ? `;expires=${expires.toUTCString()}` : '';
        cookieString += path ? `;path=${path}` : '';

        document.cookie = cookieString;
    }

    /**
     * Removes cookie with given key.
     */
    public remove(key: string, path: string = '/'): void {
        let cookieString: string = `${encodeURIComponent(key)}=`;
        cookieString += ';expires=Thu, 01 Jan 1970 00:00:00 GMT';
        cookieString += path ? `;path=${path}` : '';

        document.cookie = cookieString;
    }

    /**
     * Removes all current stored cookies
     */
    public removeAll(): void {
        this.getAllKeys().forEach((key: string) => {
            this.remove(key);
        });
    }
}

function getCookiesMap(cookieString: string): Map<string, string> {
    const cookies: Map<string, string> = new Map<string, string>();

    if (!cookieString.length) {
        return cookies;
    }

    const splitCookies: Array<string> = cookieString.split('; ');
    splitCookies.forEach((cookie: string) => {
        const separatorIndex: number = cookie.indexOf(('='));

        // Ignore cookies that have no key
        if (separatorIndex <= 0) {
            return;
        }

        const key: string = safeDecodeURIComponent(cookie.substring(0, separatorIndex));
        const value: string = safeDecodeURIComponent(cookie.substring(separatorIndex + 1));
        /**
         * There can be multiple cookies for the same key (different paths).
         * The first one found is the one with the most specific path
         */
        if (cookies.has(key) && isEmptyValue(cookies.get(key))) {
            return;
        }
        cookies.set(key, value);
    });

    return cookies;
}

function isEmptyValue(obj: unknown): boolean {
    return obj === undefined || obj === null;
}

function safeDecodeURIComponent(str: string): string {
    try {
        return decodeURIComponent(str);
    } catch (e) {
        return str;
    }
}

export {CoreCookieService};
