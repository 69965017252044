import {CoreCookieService} from './cookie.service';

class AuthToken {
    public static readonly tokenLocalStorageKey: string = 'aa-manual-token';

    /**
     * Can be called with a set cookie string in the constructor
     * Usefull if you want to get cookies on the server side.
     *
     * In the client this should always be use with the static methods to always
     * get the latest data from document.cookie
     */
    constructor(private cookieString: string = document.cookie || '') {}

    public has(): boolean {
        return new CoreCookieService(this.cookieString).get(AuthToken.tokenLocalStorageKey) !== null;
    }

    public get(): string | null {
        return new CoreCookieService(this.cookieString).get(AuthToken.tokenLocalStorageKey);
    }

    public static has(): boolean {
        return new CoreCookieService().get(AuthToken.tokenLocalStorageKey) !== null;
    }

    public static set(token: string): void {
        new CoreCookieService().set(AuthToken.tokenLocalStorageKey, token);
    }

    public static get(): string | null {
        return new CoreCookieService().get(AuthToken.tokenLocalStorageKey);
    }

    public static remove(): void {
        new CoreCookieService().remove(AuthToken.tokenLocalStorageKey);
    }
}

export {AuthToken};
