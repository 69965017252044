import * as React from 'react';
import {styled, alpha} from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import ResetIcon from '@mui/icons-material/Close';
import CloseSearchIcon from '@mui/icons-material/ArrowBack';
import {IconButton, InputAdornment} from '@mui/material';

interface ISearchState {
    isOpened: boolean;
    searchQuery: string;
}
class SearchInput extends React.Component<{}, ISearchState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            isOpened: false,
            searchQuery: '',
        };
    }

    public render(): React.ReactNode {
        return (
            <Search>
                {(this.state.isOpened)
                    ? (
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{'aria-label': 'search'}}
                            value={this.state.searchQuery}
                            onChange={(element: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({searchQuery: element.target.value});
                            }}
                            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                if (event.key === 'Enter') {
                                    const value: string = (event.target as HTMLInputElement).value;
                                    if (value.length < 3) {
                                        return;
                                    }

                                    const encodedValue: string = encodeURIComponent(value);
                                    const language: string = location.pathname.startsWith('/en')
                                        ? '/en'
                                        : '/de';

                                    location.href = `${language}/search?term=${encodedValue}`;
                                } else if (event.key === 'Escape') {
                                    this.toggleSearch();
                                }
                            }}
                            autoFocus={true}
                            startAdornment={
                                <InputAdornment position="start">
                                    <IconButton
                                        onClick={this.toggleSearch.bind(this)}
                                        sx={{color: '#757575'}}
                                    ><CloseSearchIcon /></IconButton>
                                </InputAdornment>
                            }
                            endAdornment={
                                this.state.searchQuery.length > 0 && <InputAdornment position="end">
                                    <IconButton
                                        onClick={this.resetSearch.bind(this)}
                                        sx={{color: '#757575', mr: 1}}
                                    ><ResetIcon /></IconButton>
                                </InputAdornment>
                            }
                        />
                    )
                    : (
                        <IconButton
                            onClick={this.toggleSearch.bind(this)}
                            sx={{color: '#757575', mr: this.isDForceBuild() ? 0 : 2}}
                            aria-label="glossary"
                            edge="start"
                        >
                            <SearchIcon />
                        </IconButton>
                    )
                }
            </Search>
        );
    }

    private toggleSearch(): void {
        this.setState({isOpened: !this.state.isOpened});
    }

    private isDForceBuild(): boolean {
        return process.env.GATSBY_IS_DFORCE === 'true';
    }

    private resetSearch(): void {
        this.setState({
            searchQuery: '',
        });
    }
}

const Search = styled('div')(({theme}) => ({
    'borderRadius': theme.shape.borderRadius,
    'backgroundColor': alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    'marginLeft': 0,
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
    'color': '#757575',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        transition: theme.transitions.create('width'),
        width: '100%',
    },
    'position': 'absolute',
    'left': 0,
    'top': 0,
    'height': '56px',
    'width': '100%',
    'backgroundColor': 'white',
    'zIndex': 2,
    [theme.breakpoints.up('sm')]: {
        height: '64px',
    },
}));

export {SearchInput};
