import {combineReducers, configureStore} from '@reduxjs/toolkit';
import menuItemsReducer from './menu-items.slice';

// assume that the counter slice will be combined with other slices
const reducer = combineReducers({
    menuItems: menuItemsReducer,
});

// create the store from the combined reducer
const store = configureStore({reducer});

export default store;

// typescript type for the combined state
export type State = ReturnType<typeof reducer>;

export const mapStateToProps = (state: State) => ({
    menuItems: state.menuItems.value,
});
