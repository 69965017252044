import {createSlice} from '@reduxjs/toolkit';
import {IGraphQLMenuEntry} from '../../graphql.types';

// basic example slice copied from the docs
const menuItemsSlice = createSlice({
    name: 'menuItems',
    initialState: {
        value: [],
    },
    reducers: {
        updateMenuItems: (state, action) => {
            state.value = action.payload;
        },
    },
});

// destructure actions and reducer from the slice (or you can access as menuItemsSlice.actions)
const {actions, reducer} = menuItemsSlice;

// export individual action creator functions
export const {updateMenuItems} = actions;

// often the reducer is a default export, but that doesn't matter
export default reducer;

export interface IUpdateMenuItemsProps {
    updateMenuItems: (state: Array<IGraphQLMenuEntry>) => void;
}
