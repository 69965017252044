import * as React from 'react';
import * as Sentry from '@sentry/browser';

interface IErrorBoundaryState {
    hasError: boolean;
}

/**
 * https://www.gatsbyjs.com/plugins/gatsby-plugin-sentry/
 */
class ErrorBoundary extends React.Component<{}, IErrorBoundaryState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            hasError: false,
        };
    }

    public static getDerivedStateFromError(error: Error): {hasError: boolean; error: Error} {
        return {
            hasError: true,
            error,
        };
    }

    public componentDidCatch(error: Error, info: React.ErrorInfo): void {
        Sentry.configureScope((scope: Sentry.Scope) => {
            Object.keys(info).forEach((key: string) => {
                // some typings are wrong here. didn't manage to get it right without the suppress
                // @ts-ignore
                scope.setExtra(key, info[key]);
            });
        });
        Sentry.captureException(error);
    }

    public render(): React.ReactNode {
        if (this.state.hasError) {
            return <h1>Ein Fehler ist aufgetreten.</h1>;
        }

        return this.props.children;
    }
}

export {ErrorBoundary};
