module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://47033f3195ea47539f808bfb510b031c@error.active-agent.com/8","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"active-agent-manual","short_name":"manual","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d7926e60a2a50fe0f008c4d5c2f94446"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://mm.benjaminvelte.de/graphql","searchAndReplace":[{"search":"https://mm.benjaminvelte.de/wp-content/uploads","replace":"https://cdn.mm.benjaminvelte.de/wp-content/uploads"},{"search":"https://mm.benjaminvelte.de/wp-content/uploads","replace":"https://cdn.mm.benjaminvelte.de/wp-content/uploads"},{"search":"https://mm.benjaminvelte.de","replace":"https://mm.benjaminvelte.de"}],"type":{"__all":{},"MediaItem":{"createFileNodes":false,"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"html":{"useGatsbyImage":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":90,"createStaticFiles":true,"generateWebpImages":false,"placeholderType":"blurred"},"schema":{"timeout":30000000,"perPage":200,"requestConcurrency":100,"previewRequestConcurrency":5,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"debug":{"timeBuildSteps":true,"graphql":{"showQueryOnError":true,"showQueryVarsOnError":false,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false},"preview":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"verbose":true},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    }]
